'use client';

import { useHttp, useHttpRequest } from '@grupa-pracuj/http-client-relax/axios';

import httpStatus from 'http-status';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect } from 'react';
import getCSRFValidation from 'src/utils/http-requests/get-csrf-validation';

const CSRFValidation = () => {
  const http = useHttp();
  const router = useRouter();
  const csrfValidationRequest = useHttpRequest(http, getCSRFValidation);

  const validateCSRFToken = useCallback(async () => {
    const { status } = await csrfValidationRequest.handleRequest({});

    if (status === httpStatus.FORBIDDEN) {
      return router.refresh();
    }

    return null;
  }, [csrfValidationRequest]);

  useEffect(() => {
    window.addEventListener('focus', validateCSRFToken);

    return () => {
      window.removeEventListener('focus', validateCSRFToken);
    };
  }, [validateCSRFToken]);

  return null;
};

export default CSRFValidation;
